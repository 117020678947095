import { Box, Typography } from "@mui/material";
import React from "react";

const anoCorrente = new Date().getFullYear();
const projectVersion = process.env.APP_VERSION ?? "3.2.0";

export const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        bgcolor: "background.paper",
        py: 2,
        textAlign: "center",
      }}
    >
      <Typography variant="body2" color="textSecondary">
        © {anoCorrente} | abreZap.app.br | ver. {projectVersion}
      </Typography>
    </Box>
  );
};
