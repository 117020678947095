import { Box } from "@mui/material";
import React from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";

export const ShareButtons: React.FC = () => {
  const url = "https://abreZap.app.br";
  const title = "Achei este site muito útil para enviar mensagens no WhatsApp sem adicionar o número aos contatos.";

  return (
    <Box
      display="flex"
      justifyContent="center"
      gap={2}
      sx={{
        position: "fixed",
        bottom: "34px",
        left: 0,
        width: "100%",
        bgcolor: "background.paper",
        py: 2,
        textAlign: "center",
        boxShadow: "0 -1px 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      <FacebookShareButton url={url} title={title}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <WhatsappShareButton url={url} title={title}>
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      <TwitterShareButton url={url} title={title}>
        <XIcon size={32} round />
      </TwitterShareButton>
      <LinkedinShareButton url={url} title={title}>
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
    </Box>
  );
};
