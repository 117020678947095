import axios, { AxiosError } from "axios";
import _ from "lodash";
import { toast } from "react-toastify";

export const setupAxiosInterceptors = () => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError) => {
      if (error.response && error.response.status >= 400) {
        const apiError = error.response.data as { message?: string; errors?: string[] };
        const errorMessage = _.first(apiError?.errors) ?? apiError?.message ?? "Erro desconhecido";
        toast.error(<div style={{ whiteSpace: "pre-line" }}>{errorMessage}</div>);
      }
      return Promise.reject(error);
    },
  );
};
