import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { formatPhoneNumber } from "react-phone-number-input";

type QrcodeModalProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  qrCode?: string | null;
  phone?: string;
};

export const QrcodeModal = ({ isModalOpen, closeModal, qrCode, phone }: QrcodeModalProps): JSX.Element => {
  return (
    <Modal
      open={isModalOpen}
      onClose={closeModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          bgcolor: "background.paper",
          p: 3,
          borderRadius: 2,
          boxShadow: 24,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "90%",
          maxWidth: 300,
          mx: "auto",
        }}
      >
        <IconButton aria-label="close" onClick={closeModal} sx={{ position: "absolute", right: 8, top: 8, color: "grey.600" }}>
          <CloseIcon />
        </IconButton>

        {qrCode && <img src={qrCode} alt="QR Code" style={{ margin: "10px 0", maxWidth: "100%" }} />}

        <Typography id="modal-description" variant="h6" sx={{ fontSize: "1.2rem", color: "text.primary", lineHeight: "1.17", mt: 2, textAlign: "center" }}>
          Aponte a câmera do seu celular para abrir um chat de Whatsapp com o número <strong>{phone ? formatPhoneNumber(phone) : ""}</strong>.
        </Typography>
      </Box>
    </Modal>
  );
};
