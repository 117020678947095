import { Button } from "@mui/material";
import { isValidPhoneNumber } from "react-phone-number-input";

export type AbrezapButtonProps = {
  phone?: string;
  handleAbreZap: () => void;
};

export const AbrezapButton = ({ phone, handleAbreZap }: AbrezapButtonProps): JSX.Element => {
  return (
    <Button
      variant="contained"
      sx={{ bgcolor: "#4CAF50", "&:hover": { bgcolor: "#45a049" }, px: 3, py: 1 }}
      onClick={handleAbreZap}
      disabled={!isValidPhoneNumber(phone ?? "")}
    >
      abreZap
    </Button>
  );
};
