import PhoneInput, { Country as CountryCode } from "react-phone-number-input";
import "react-phone-number-input/style.css";

type PhoneInternacionalProps = {
  phone?: string;
  setPhone: React.Dispatch<React.SetStateAction<string | undefined>>;
  userCountry?: CountryCode;
};

export const PhoneInternacional = ({ phone, setPhone, userCountry }: PhoneInternacionalProps): JSX.Element => {
  return (
    <PhoneInput
      displayInitialValueAsLocalNumber
      value={phone}
      onChange={setPhone}
      defaultCountry={userCountry}
      countryCallingCodeEditable={false}
      numberInputProps={{
        name: "telefone",
        placeholder: "Digite um número de telefone.",
        style: {
          backgroundColor: "background.paper",
          outline: "none",
          fontSize: "22px",
          fontWeight: 500,
          textAlign: "center",
          fontFamily: "roboto",
          paddingRight: "20px",
          paddingTop: "10px",
          paddingBottom: "10px",
          borderRadius: "10px",
          borderBottom: "1px solid #45a049",
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
          width: "100%",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        },
      }}
      style={{ marginBottom: 20, width: "100%" }}
    />
  );
};
