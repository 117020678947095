import { Box, Button, Modal, Rating, TextField, Typography } from "@mui/material";
import { useState } from "react";

interface RatingCommentModalProps {
  onSubmit: (rating: number, comment: string) => void;
}

export const RatingCommentModal: React.FC<RatingCommentModalProps> = ({ onSubmit }) => {
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState(5);
  const [comment, setComment] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = () => {
    onSubmit(rating, comment);
    setRating(5);
    setComment("");
    handleClose();
  };

  return (
    <Box paddingTop="45px">
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Avaliar app
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "75%",
            maxWidth: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Avalie nosso app
          </Typography>
          <Rating
            name="rating"
            value={rating}
            size="large"
            onChange={(event, newValue) => {
              setRating((newValue as number) ?? 0);
            }}
          />
          <TextField
            placeholder="Deixe seu comentário, elogio ou sugestão..."
            multiline
            rows={4}
            fullWidth
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            sx={{ mt: 2 }}
          />
          <Box mt={3} textAlign="right">
            <Button onClick={handleClose} sx={{ mr: 1 }}>
              Cancelar
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Enviar
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
