import { Button } from "@mui/material";
import { isValidPhoneNumber } from "react-phone-number-input";

type GerarQrcodeButtonProps = {
  handleGerarQRCode: () => void;
  phone?: string;
};

export const GerarQrcodeButton = ({ handleGerarQRCode, phone }: GerarQrcodeButtonProps): JSX.Element => {
  return (
    <Button
      variant="contained"
      sx={{ bgcolor: "#da4e0d", "&:hover": { bgcolor: "#c7470c" }, px: 3, py: 1 }}
      onClick={handleGerarQRCode}
      disabled={!isValidPhoneNumber(phone ?? "")}
    >
      gerar QRCode
    </Button>
  );
};
