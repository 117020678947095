import { Box, Typography } from "@mui/material";
import React from "react";

export const Descricao: React.FC = () => {
  return (
    <Box id="texto-descricao" mt={4} color="textSecondary">
      <Typography variant="body2">
        <strong>abreZap</strong> é um serviço online 🤑 <strong>GRÁTIS</strong> 🤑 que permite iniciar um chat de WhatsApp sem adicionar o número aos contatos,
        inclusive para números internacionais. O código do país é reconhecido automaticamente. E o melhor! Funciona em qualquer dispositivo com acesso à
        internet e com o app oficial do{" "}
        <a href="https://www.whatsapp.com/download" target="_blank" rel="noopener noreferrer">
          WhatsApp
        </a>{" "}
        instalado.
      </Typography>
    </Box>
  );
};
